import {BsFillTrash3Fill} from 'react-icons/bs';
import {IoArrowUndo} from 'react-icons/io5';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    addProductToCart,
    initUser,
    removeProductFromCart,
    removeProductFromCartMulti,
} from '../../redux/reducers/user';
import {websiteUrl} from '../Homepage/Homepage';
import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const Cartpage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [userData, setUserData] = useState();
    const [paymentLink, setPaymentLink] = useState(null);
    const [uniqueProducts, setUniqueProducts] = useState({});

    const Pay = async () => {
        try {
            const merchant_id = '48198';
            const sum_value = String(user.cart.total);
            const secret_word = 'g3Fc&t.V)UMb=_Y';
            const currency = 'RUB';
            const date = new Date();
            const order_id = date * 1000;

            const data = {
                _id: order_id,
                userId: user.id,
                total: sum_value,
                status: 'Ожидает оплаты',
                products: user.cart.products,
                date: new Date(),
            };

            await axios(`${websiteUrl}/create-order`, {
                method: 'post',
                headers: {
                    'Content-type': 'Application/json',
                    Accept: 'Application/json',
                },
                data: data,
            });

            const wataData = {
                amount: sum_value,
                description: `Оплата заказа №${order_id}`,
                success_url: `${websiteUrl}/payment-success`,
                fail_url: `${websiteUrl}/payment-failed`,
                merchant_order_id: order_id,
                currency: 'RUB',
            };
            const response = await axios.post(
                `${websiteUrl}/wata/payment-link`,
                wataData
            );

            if (response.status === 200) {
                console.log(response.data);
                setPaymentLink(response.data.acquiring_page);
            }
        } catch (error) {
            console.error('Ошибка при выполнении оплаты:', error);
        }
    };

    useEffect(() => {
        Pay();
    }, []);

    useEffect(() => {
        const uniqueProductsObj = {};
        user.cart.products.forEach((product) => {
            if (uniqueProductsObj[product.id]) {
                uniqueProductsObj[product.id].count++;
            } else {
                uniqueProductsObj[product.id] = {
                    id: product.id,
                    title: product.title,
                    value: product.value,
                    price: product.price,
                    photoSrc: product.photoSrc,
                    isPremium: product.isPremium,
                    count: 1,
                };
            }
        });
        setUniqueProducts(uniqueProductsObj);
    }, [user.cart.products]);

    const add = (product) => {
        dispatch(addProductToCart(product));
    };

    const remove = (product) => {
        dispatch(removeProductFromCart(product));
    };

    const removeAll = (product) => {
        dispatch(removeProductFromCartMulti(product));
    };

    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            const authUser = JSON.parse(
                decodeUrl(window.Telegram.WebApp.initData).split('&')[1].split('=')[1]
            );
            const query_id = decodeUrl(window.Telegram.WebApp.initData)
                .split('&')[0]
                .split('=')[1];
            const hash = decodeUrl(window.Telegram.WebApp.initData)
                .split('&')[3]
                .split('=')[1];
            const auth_date = decodeUrl(window.Telegram.WebApp.initData)
                .split('&')[2]
                .split('=')[1];
            setUserData({...authUser, hash, auth_date, query_id});
        }
    }, []);

    useEffect(() => {
        if (userData) {
            axios.post(`${websiteUrl}/get-user-or-create`, userData).then((res) => {
                dispatch(initUser(res.data));
            });
        }
    }, [userData, dispatch]);

    return (
        <div className="cart">
            <div className="wrapper">
                <div className="df ai-c jc-sb">
                    <h1>Корзина</h1>
                    <Link to="/">
                        <IoArrowUndo className="back-icon"/>
                    </Link>
                </div>

                <div className="cart-list">
                    {user.cart.total === 0 ? (
                        <div>
                            Кажется, вы ничего не выбрали. Добавьте что-нибудь из{' '}
                            <Link style={{textDecoration: 'underline'}} to="/">
                                каталога
                            </Link>{' '}
                            и возвращайтесь!
                        </div>
                    ) : (
                        Object.values(uniqueProducts).map((product) => (
                            <div className="cart-card" key={product.id}>
                                <div className="df gap-10">
                                    <div className={`box ${product.isPremium ? 'black' : null}`}>
                                        <img
                                            src={product.photoSrc}
                                            alt={`Card value: ${product.value}`}
                                            className="card-image"
                                        />
                                    </div>
                                    <div className="cart-card-info">
                                        <h4 className="cart-card-title">{product.title}</h4>
                                        <div className="card-info-attributes">
                                            <p className="info-item">
                                                <span className="label">Цена:</span> <span
                                                className="value">{product.price} ₽</span>
                                            </p>
                                            <p className="info-item">
                                                <span className="label">Номинал карты:</span> <span
                                                className="value">{product.value}</span>
                                            </p>
                                            <p className="info-item">
                                                <span className="label">Кол-во:</span> <span
                                                className="value">{product.count}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="df fd-col ai-fe jc-sb">
                                    {/*<div className="cart-card-counter">*/}
                                        {/*<div className="add-btn" onClick={() => remove(product)}>*/}
                                        {/*    -*/}
                                        {/*</div>*/}
                                        {/*<div className="label">Кол-во:</div>*/}
                                        {/*<div className="value">{product.count}</div>*/}
                                        {/*<div className="add-btn" onClick={() => add(product)}>*/}
                                        {/*    +*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<BsFillTrash3Fill*/}
                                    {/*    className="delete-all-icon"*/}
                                    {/*    onClick={() => removeAll(product)}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        ))
                    )}

                    <div className="summary">
                        <h3>
                            Итого: <span>{user.cart.total} ₽</span>
                        </h3>
                        {paymentLink ? (
                            <button
                                onClick={() => window.open(paymentLink, '_blank', 'noopener,noreferrer')}
                                className={`pay-button ${user.cart.total === 0 ? 'deactive' : ''}`}
                            >
                                Оплатить
                            </button>
                        ) : (
                            <button disabled className="pay-button deactive">
                                Загрузка...
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

function decodeUrl(str) {
    return str
        .replace(/%7B/g, '{')
        .replace(/%22/g, '"')
        .replace(/%7D/g, '}')
        .replace(/%3A/g, ':')
        .replace(/%2C/g, ',')
        .replace(/%2F/g, '/')
        .replace(/%3F/g, '?')
        .replace(/%3D/g, '=')
        .replace(/%26/g, '&')
        .replace(/%2B/g, '+')
        .replace(/%25/g, '%');
}

export default Cartpage;
