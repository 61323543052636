import React, {useEffect, useState} from 'react';
import Catalog from '../../components/Catalog';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {initUser} from '../../redux/reducers/user';
import {Link} from 'react-router-dom';
import Orders from "../Orders/Orders";
import Info from "../Info/Info";

export const websiteUrl = 'https://web-app-karta-itunes.ru/api';

const Homepage = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState();
    const [auth, setAuth] = useState(false);
    const user = useSelector((state) => state.user);
    const [activeComponent, setActiveComponent] = useState('catalog'); // Дефолтное значение
    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            const authUser = JSON.parse(decodeUrl(window.Telegram.WebApp.initData).split('&')[1].split('=')[1]);
            const query_id = decodeUrl(window.Telegram.WebApp.initData).split('&')[0].split('=')[1];
            const hash = decodeUrl(window.Telegram.WebApp.initData).split('&')[3].split('=')[1];
            const auth_date = decodeUrl(window.Telegram.WebApp.initData).split('&')[2].split('=')[1];
            setUserData({...authUser, hash, auth_date, query_id});
            setAuth(true)
        } else {
            setAuth(false)
        }
    }, []);

    useEffect(() => {
        if (userData) {
            sessionStorage.setItem('userId', userData.id);
            axios.post(`${websiteUrl}/get-user-or-create`, userData).then((res) => {
                dispatch(initUser(res.data));
            });
        }
    }, [userData, dispatch]);


    return (
        <div className="wrapper">

            <div className="menu">
                <a href="#" onClick={() => setActiveComponent('catalog')} className="menu-item" tabIndex="1">
                    <img src="https://img.icons8.com/ios-glyphs/30/3b7ddb/grid.png" alt="Каталог"/>
                    <span>Каталог</span>
                </a>
                <a href="#" onClick={() => setActiveComponent('orders')} className="menu-item" tabIndex="2">
                    <img src="https://img.icons8.com/ios-glyphs/30/3b7ddb/order-history.png" alt="Заказы"/>
                    <span>Заказы</span>
                </a>
                <a href="https://t.me/kartaItunesSupport" className="menu-item" tabIndex="3">
                    <img src="https://img.icons8.com/ios-glyphs/30/3b7ddb/phone.png" alt="Связь"/>
                    <span>Поддержка</span>
                </a>
                <a href="#" onClick={() => setActiveComponent('info')} className="menu-item" tabIndex="4">
                    <img src="https://img.icons8.com/ios-glyphs/30/3b7ddb/info.png" alt="Инфо"/>
                    <span>Инфо</span>
                </a>
            </div>
            <>
                {activeComponent === 'catalog' && <Catalog/>}
                {activeComponent === 'orders' && <Orders/>}
                {activeComponent === 'info' && <Info/>}
                {user.cart.products.length > 0 ? (
                    <div className="go-to-cart-block">
                        <div className="total-line">
                            <p>Итого</p>
                            {/*<FaCartFlatbed className="total-icon"/>*/}
                            <div className="total-line-dash"></div>
                            <div className="total-counter">{user.cart.total} ₽</div>
                        </div>

                        <Link to="/cart" className="default-btn">
                            Оформить заказ
                        </Link>
                    </div>
                ) : null}
            </>
        </div>
    );
};

function decodeUrl(str) {
    return str
        .replace(/%7B/g, '{')
        .replace(/%22/g, '"')
        .replace(/%7D/g, '}')
        .replace(/%3A/g, ':')
        .replace(/%2C/g, ',')
        .replace(/%2F/g, '/')
        .replace(/%3F/g, '?')
        .replace(/%3D/g, '=')
        .replace(/%26/g, '&')
        .replace(/%2B/g, '+')
        .replace(/%25/g, '%');
}

export default Homepage;
